<template>
    <div class="operation-action-library-list">
        <div class="operation-action-library-list-head">
            <div class="operation-action-library-list-head-operation">
                <div class="item" @click="handleAdd('add')">
                    <i class="iconfont icon-benzhouxinzengfenxiaoshang" />新增
                </div>
                <!-- <div class="item" @click="handleImport">
                    <i class="iconfont icon-daoru" />导入
                </div>
                <div class="item" @click="handleDisabled">
                    <i class="iconfont icon-tingyong" />停用
                </div> -->
            </div>
            <!-- <span>当前关注数：xxx</span> -->
        </div>
        <div class="operation-action-library-list-main">
            <el-table
                :data="list"
                style="width: 100%;"
                height="500"
                row-key="id"
            >
                <el-table-column
                    align="left"
                    label="序号"
                    width="100"
                    prop="index"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 }}</span>
                    </template>
                </el-table-column>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="动作"
                    prop="actionName"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    label="动作编码"
                    prop="actionNo"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    label="主题"
                    prop="actionSubject"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    label="监控时长"
                    prop="monitorInterval"
                >
                </el-table-column>
                <el-table-column
                    align="center"
                    label="说明"
                    prop="description"
                >
                </el-table-column>
                <el-table-column align="center" label="操作" prop="desc">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="handleAdd('edit', scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            @click="handleAdd('info', scope.row)"
                        >
                            详情
                        </el-button>
                        <el-button
                            type="text"
                            @click="handleRemoveItem(scope.row)"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <update-dialog ref="updateDialog" v-on="$listeners"></update-dialog>
    </div>
</template>
<script>
import sellSvc from 'services/sell'

export default {
    name: 'OperationActionLibraryList',
    components: {
        updateDialog: () => import('./updateDialog')
    },
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        handleItem () {

        },
        handleAdd (dialogType, { id } = {}) {
            this.$refs.updateDialog.handleDialog(id, dialogType)
        },
        async handleRemoveItem ({ id } = {}) {
            this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                console.log('更新删除')
                const { data } = await sellSvc.removeActionRecord({ id })
                this.$emit('handle-refresh')
            }).catch(() => {
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.operation-action-library-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 13px;
    overflow: hidden;

    &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;

        & + div {
            margin-top: 19px;
        }

        &-operation {
            display: flex;
            align-items: center;
        }

        &-operation .item {
            margin-right: 19px;
            font-weight: 400;
            color: #3595e1;
            cursor: pointer;
        }

        &-operation .item i {
            margin-right: 9px;
            font-size: 12px;
        }

        & > span {
            color: #606266;
        }
    }

    &-main {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow: hidden;
    }

    ::v-deep.el-table {
        .has-gutter th {
            font-size: 14px;
            font-weight: 500;
            color: #606266;
            background-color: #f7f9fc;
        }

        .expanded + tr:not(.el-table__row) > td {
            padding: 0;
            background: #f7f9fc;

            &:hover {
                background: #f7f9fc !important;
            }

            .el-pagination * {
                background: transparent;
            }
        }

        .el-table--fit {
            background-color: #f7f9fc;
        }
    }

    ::v-deep.el-tabs__nav-wrap {
        padding: 0 116px;

        .el-tabs__item {
            color: #909399;
        }
    }

    ::v-deep.el-table th.is-leaf {
        font-size: 14px;
        font-weight: 500;
        color: #606266;
        background-color: #f7f9fc;
    }

    ::v-deep.el-tabs__header {
        margin: 0;
    }

    ::v-deep.el-tabs__content .el-table tr {
        background-color: #f7f9fc;
    }
}
</style>
